import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { addDescription, getAllDescription, getSingleDescription, updateDescription, uploadTextEditorImage } from "src/api/service";
import { useLocation } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Alert } from "bootstrap";
import axios from "axios";

//import CustomEditor from "./ckeditor";

const editorConfig = {
  toolbar: {
    items: [
      'heading', // This is the heading dropdown
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'insertImage', // Insert Image option
      'insertTable', // Insert Table option
      'insertMedia', // Insert Media option
      'fontColor', // Font Color option
      '|',
      'undo',
      'redo',
      '|',
      'fontSize', // Font Size option
    ],
    shouldNotGroupWhenFull: true // Allows adding items to the toolbar without grouping
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }, // Custom heading option
      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }, // Custom heading option
      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' } // Custom heading option
    ]
  },
  fontSize: {
    options: ['tiny', 'small', 'big', 'default', 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40]
  }
};

export default function DescriptionPage(descriptionDatabyID) {
  const router = useLocation();

  const descriptionObj = {
    description: "",
    image: "",
    serviceId: router?.state?.id,
  };

  const [descriptionAction, setDescriptionAction] = useState("create");
  const [descriptionId, setDescriptionId] = useState("");
  const [descriptionSection, setDescriptionSection] = useState(descriptionObj);
  const [description, setdescription] = useState("");
  const imageRef = useRef();
  useEffect(() => {
    getDescriptionFunction();
  }, []);

  const handleDescription = (e) => {
    const { name, value } = e.target;
    setDescriptionSection({ ...descriptionSection, [name]: value });
  };

  const handleDescriptionSection = () => {

    const descriptionFormData = new FormData();
    descriptionFormData.append("description", description)
    descriptionFormData.append("image", imageRef?.current?.files[0])
    descriptionFormData.append("serviceId", router?.state?.id)
    if (descriptionAction === "create") {
      addDescription(descriptionFormData)
        .then((res) => {
          getDescriptionFunction();
          setdescription("");
          setDescriptionSection(descriptionObj);
          setDescriptionAction("create");
        })
        .catch((err) => Alert("err", err));
    } else {
      updateDescription(descriptionId, descriptionFormData)
        .then((res) => {
          getDescriptionFunction();
          setdescription("");
          setDescriptionSection(descriptionObj);
          setDescriptionAction("create");
        })
        .catch((err) => Alert("err", err));
    }
  };

  const getSingleDescriptions = (id) => {
    setDescriptionSection({ ...descriptionSection, serviceId: id });
    getSingleDescription(id)
      .then((res) => {
        setdescription(res.data.description);
        setDescriptionSection(res.data);
      })
      .catch((err) => Alert("err", err));
  };

  const getDescriptionFunction = () => {
    getAllDescription()
      .then((res) => { })
      .catch((err) => Alert("err", err));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
  };
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const combinedEditorConfig = {
    ...editorConfig,
    extraPlugins: [MyCustomUploadAdapterPlugin], // Add custom upload adapter plugin
  };
  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                console.log("Description Section", response)
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Description Section</strong>
      </CCardHeader>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Image URL</CTableHeaderCell>
              <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {(Array.isArray(descriptionDatabyID?.descriptionDatabyID) ? descriptionDatabyID?.descriptionDatabyID : [descriptionDatabyID?.descriptionDatabyID])?.map((item, index) => {
              return (
                <CTableRow key={index}>
                  <CTableDataCell>{index + 1}</CTableDataCell>
                  <CTableDataCell>{item?.image}</CTableDataCell>
                  <CTableDataCell>
                    <div dangerouslySetInnerHTML={{
                      __html: item?.description && item.description.split(' ').slice(0, 11).join(' ') + (item.description.split(' ').length > 10 ? ' .....' : '')

                    }} />
                  </CTableDataCell>

                  <CTableDataCell>
                    <>
                      <Tooltip text="View">
                        <FontAwesomeIcon
                          role="button"
                          icon={faEye}
                          size="1x"
                          className="viewIcon"
                        />
                      </Tooltip>

                      <Tooltip text="Update">
                        <FontAwesomeIcon
                          role="button"
                          icon={faPenToSquare}
                          size="1x"
                          className="px-3 updateIcon"
                          onClick={() => {
                            setDescriptionAction("edit");
                            setDescriptionId(item?.id);
                            getSingleDescriptions(item?.id);
                          }}
                        />
                      </Tooltip>
                    </>
                  </CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />
      <CCardBody>
        <CForm>
          <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="file"
                id="exampleFormControlInput1"
                label="Image Url"
                ref={imageRef}
                onChange={handleDescription}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <br />
          <CRow className="align-items-start">
            <CFormLabel>Description</CFormLabel>
            <CKEditor
              editor={ClassicEditor}
              // config={editorConfig}
              data={description}
              onChange={handleEditorChange}
              config={combinedEditorConfig}
            />
          </CRow>
          <br />

          <br />
          <CButton onClick={handleDescriptionSection}>Submit</CButton>
        </CForm>
      </CCardBody>
    </CCard>
  );
}
